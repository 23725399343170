<template>
  <ui-form @submit="handleSubmit" @cancel="handleCancel">
    <ui-form-field :label="$t('fields.email')" rules="required|email">
      <el-input type="email" v-model="user.email"></el-input>
    </ui-form-field>

    <ui-form-field :label="$tc('models.role')" v-if="user.role">
      <div>{{ user.role.name }}</div>
    </ui-form-field>

    <ui-form-field :label="$tc('models.building')" v-if="user.building">
      <div>{{ user.building.name }}</div>
    </ui-form-field>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    UiForm,
    UiFormField
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
